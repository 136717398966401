// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as ko from 'knockout';
import "./ko.init";
import komapping from 'knockout.mapping';
import 'knockout-switch-case';
import 'knockout-sortable';
import 'knockout.validation';
import "../ext/ko.editable.custom";
import "../ext/ko.selectize.custom";
import "./bindings/charts/mapchart/ko.mapChart";
import "./bindings/charts/ko.barChart";
import "./bindings/charts/ko.leafletMapChart";
import "./bindings/charts/ko.lineChart";
import "./bindings/charts/ko.partitionChart";
import "./bindings/charts/ko.pieChart";
import "./bindings/charts/ko.scatterChart";
import "./bindings/charts/ko.timelineChart";
import "./bindings/ko.vue.js";
import "./bindings/ace/ko.aceEditor";
import "./bindings/ace/ko.aceResizer";
import "./bindings/ko.appAwareTemplateContextMenu";
import "./bindings/ko.assistFileDraggable";
import "./bindings/ko.assistFileDroppable";
import "./bindings/ko.assistVerticalResizer";
import "./bindings/ko.attachViewModelToElementData";
import "./bindings/ko.augmentHtml";
import "./bindings/ko.autocomplete";
import "./bindings/ko.autogrowInput";
import "./bindings/ko.blurHide";
import "./bindings/ko.bytesize";
import "./bindings/ko.chosen";
import "./bindings/ko.clearable";
import "./bindings/ko.clickForAceFocus";
import "./bindings/ko.clickToCopy";
import "./bindings/ko.clipboard";
import "./bindings/ko.codemirror";
import "./bindings/ko.contextMenu";
import "./bindings/ko.contextSubMenu";
import "./bindings/ko.datalist";
import "./bindings/ko.datepicker";
import "./bindings/ko.dateRangePicker";
import "./bindings/ko.dblClick";
import "./bindings/ko.delayedOverflow";
import "./bindings/ko.dockable";
import "./bindings/ko.documentChooser";
import "./bindings/ko.documentContextPopover";
import "./bindings/ko.draggableText";
import "./bindings/ko.dropdown";
import "./bindings/ko.dropzone";
import "./bindings/ko.duration";
import "./bindings/ko.ellipsis";
import "./bindings/ko.fadeVisible";
import "./bindings/ko.fetchMore";
import "./bindings/ko.fileChooser";
import "./bindings/ko.foreachVisible";
import "./bindings/ko.fresherEditor";
import "./bindings/ko.hdfsAutocomplete";
import "./bindings/ko.hdfsTree";
import "./bindings/ko.highlight";
import "./bindings/ko.hiveChooser";
import "./bindings/ko.html";
import "./bindings/ko.hueach";
import "./bindings/ko.hueCheckAll";
import "./bindings/ko.hueCheckbox";
import "./bindings/ko.hueChecked";
import "./bindings/ko.hueLink";
import "./bindings/ko.hueSpinner";
import "./bindings/ko.impalaDagre";
import "./bindings/ko.jHueRowSelector";
import "./bindings/ko.logResizer";
import "./bindings/ko.logScroller";
import "./bindings/ko.medium";
import "./bindings/ko.moment";
import "./bindings/ko.momentFromNow";
import "./bindings/ko.multiCheck";
import "./bindings/ko.multiCheckForeachVisible";
import "./bindings/ko.multiClick";
import "./bindings/ko.multiLineEllipsis";
import "./bindings/ko.numberFormat";
import "./bindings/ko.numericTextInput";
import "./bindings/ko.onClickOutside";
import "./bindings/ko.oneClickSelect";
import "./bindings/ko.parseArguments";
import "./bindings/ko.publish";
import "./bindings/ko.reactWrapper";
import "./bindings/ko.readOnlyAce";
import "./bindings/ko.resizable";
import "./bindings/ko.select2";
import "./bindings/ko.simplesize";
import "./bindings/ko.slider";
import "./bindings/ko.slideVisible";
import "./bindings/ko.solrChooser";
import "./bindings/ko.spinEdit";
import "./bindings/ko.splitDraggable";
import "./bindings/ko.splitFlexDraggable";
import "./bindings/ko.sqlContextPopover";
import "./bindings/ko.stickVertical";
import "./bindings/ko.storageContextPopover";
import "./bindings/ko.stretchDown";
import "./bindings/ko.tagEditor";
import "./bindings/ko.tagsNotAllowed";
import "./bindings/ko.templateContextMenu";
import "./bindings/ko.templatePopover";
import "./bindings/ko.textSqueezer";
import "./bindings/ko.toggle";
import "./bindings/ko.toggleOverflow";
import "./bindings/ko.tooltip";
import "./bindings/ko.truncatedText";
import "./bindings/ko.typeahead";
import "./bindings/ko.verticalSlide";
import "./bindings/ko.visibleOnHover";
import "./components/appSwitcher/ko.appSwitcher";
import "./components/assist/ko.assistDashboardPanel";
import "./components/assist/ko.assistDbPanel";
import "./components/assist/ko.assistDocumentsPanel";
import "./components/assist/ko.assistEditorContextPanel";
import "./components/assist/ko.assistFunctionsPanel";
import "./components/assist/ko.assistGitPanel";
import "./components/assist/ko.assistHBasePanel";
import "./components/assist/ko.assistLangRefPanel";
import "./components/assist/ko.assistPanel";
import "./components/assist/ko.assistSchedulePanel";
import "./components/assist/ko.assistStoragePanel";
import "./components/assist/ko.rightAssistPanel";
import "./components/contextPopover/ko.contextPopover";
import "./components/contextPopover/ko.quickQueryContext";
import "./components/contextPopover/ko.popoverOnHover";
import "./components/doc/ko.shareDocModal";
import "./components/simpleAceEditor/ko.simpleAceEditor";
import "./components/ko.catalogEntriesList";
import "./components/ko.contextSelector";
import "./components/ko.createDirectoryModal";
import "./components/ko.deleteDocModal";
import "./components/ko.dropDown";
import "./components/ko.editorDroppableMenu";
import "./components/ko.executionAnalysis";
import "./components/ko.favoriteApp";
import "./components/ko.fieldSamples";
import "./components/ko.globalSearch";
import "./components/ko.historyPanel";
import "./components/ko.importDocumentsModal";
import "./components/ko.inlineAutocomplete";
import "./components/ko.jobBrowserLinks";
import "./components/ko.navProperties";
import "./components/ko.navTags";
import "./components/ko.pollingCatalogEntriesList";
import "./components/ko.quickQueryAction";
import "./components/ko.sentryPrivileges";
import "./components/ko.sessionAuthModal";
import "./components/ko.sessionPanel";
import "./components/ko.shareGistModal";
import "./components/ko.sqlColumnsTable";

// TODO: Move to about app when it has it's own webpack entry
import "../apps/about/components/ko.connectorsConfig";
import "./extenders/ko.maxLength";
import "./extenders/ko.numeric";
import "./extenders/ko.toJson";
import "./observables/ko.observableArrayDefault";
import "./observables/ko.observableDefault";
window.ko = ko;
window.ko.mapping = komapping;
export default ko;